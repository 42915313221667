<template>
    <div class="main">
		<div class="wrapper">
			<div class="logo-wrapper">
				<v-img
					:src="require('../assets/logo_clock.svg')"
					contain
					width="100%"
				/>
			</div>

			<div class="form-wrapper" v-if="!linkSent">
				<div>
					<v-text-field class="textfield"
					v-model="email"
					label="Your email"
					required
					prepend-inner-icon="mdi-email"
					@keyup.enter="validate"
					></v-text-field>

					<div v-if="!valid" class="warning-wrapper">
						<div class="error-message">
							<p class="error--text">{{errorMessage}}</p>
						</div>
						<div>
							<v-icon color="error">mdi-alert-circle</v-icon>
						</div>
					</div>

					<v-btn 
					:loading="loading" 
					type="submit" 
					class="primary reset-btn" 
					@click="validate"
					>
					Reset password
					</v-btn><br>
				</div>
			</div>

			<div v-if="linkSent" class="info-wrapper mb-8">
                <h3>Password Reset Request Sent</h3>
				<br />
                <p>A password reset message was sent to your email address if your account exists. 
                    Please click the link in that message to reset your password.</p>
                <p>If you do not receive the password reset message within a few moments, 
                    please check your spam folder and other filtering tools.</p>
            </div>

			<router-link to="/" class="message--text">Go back</router-link>

			<!-- <div class="logo-wrapper">
				<v-img
					:src="require('../assets/generic_logo.png')"
					contain
					width="100%"
				/>
			</div> -->
		</div>

		<v-snackbar
			v-model="passwordResetNotifier"
			fixed
			class="snackbar-offline-notifier"
			:color="snackbarColor"
		>
			<v-flex xs12 class="align-self-center" style="font-size: 16px; text-align: center;">
				<div>{{ passwordResetNotification }}</div>
			</v-flex>
		</v-snackbar>
	</div>
</template>

<script>
//import axios from 'axios'
const axios = require('axios');
import appConfig from '@/app_config'

export default {

	name: 'ResetPassword',

	data: () => ({
		valid: true,
		loading: false,
		linkSent: false,
		email: "",
		passwordResetNotification: "",
		passwordResetNotifier: false,
		snackbarColor: "",
	}),
    props: {
    },
	computed: {
			lview: { get: function () { return this.$t('views.passwordReset'); } },
		},
    methods: {
		validate(){
			var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
			if(this.email==''){
				this.valid=false
				this.errorMessage='Required email'
				this.$forceUpdate();
			}
			else if (!this.email.match(validRegex)) {
				this.valid=false
				this.errorMessage='Incorrect email structure'
				this.$forceUpdate();
			}
			else{
				this.valid=true
				this.loading = true
				this.resetPassword()
			}
		},
		resetPassword(){
			var that = this;
			axios({
				url: appConfig.getApiUrl(null) + "/api/v1/reset-password/",
				method: "POST",
				data: {
					email: this.email
				}
			}).then(() => {
				that.linkSent=true
			}).catch(error => {
				this.loading=false
				if(error.isAxiosError){
					if(error.response == undefined || error.response == null){
						this.passwordResetNotification = "Application failed to connect to the server. Please check your internet connection.";
						this.passwordResetNotifier = true;
						this.snackbarColor = "error";
						return;
					}
					if(error.response.status == 404 || error.response.status == 400){
						this.passwordResetNotification = this.lview.couldNotResetPassword;
						this.passwordResetNotifier = true;
						this.snackbarColor = "error";
					}
					else if(error.response.status == 401){
						this.passwordResetNotification = this.lview.sendMailError;
						this.passwordResetNotifier = true;
						this.snackbarColor = "error";
					}
					else if(error.response.status == 500){
						//this.$emit('set-state', 'ERROR');
						this.passwordResetNotification = "An internal server error has occured. Please report this message to the administrator.";
						this.passwordResetNotifier = true;
						this.snackbarColor = "error";
					}
				}
			});
			// this.$router.push({name: 'send-link'})
		}
    }
	};
</script>

<style scoped>

.main{
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	padding-top: 0;
}


.wrapper{
	width: 30%;
	margin-left: 35%;
	display: flex;
	justify-content: flex-end;
	align-content: center;
	flex-wrap: wrap;
	justify-content: center;
}

.logo-wrapper{
	width: 80%;
	margin:40px 10%;
}

.form-wrapper{
	flex-basis: 100%;
	text-align: center;
}

.v-btn{
	color: #ffffff;
	width: 180px;
	padding: 2px;
	margin: 20px;
}

.reset-btn:hover{
	box-shadow: 1px 1px 5px #6a7b8b;
}

.warning-wrapper{
	display: flex;
	justify-content: space-between;
	font-size: 12px;
}


a {
	text-decoration: none;
	font-size: 14px;
}

@media only screen and (max-width: 1904px) { 
	.wrapper {
		width: 40%;
		margin-left: 30%;
		transform: translate(-120px,0);
	}
}

@media only screen and (max-width: 1264px) { 
	.wrapper {
		width: 40%;
		margin-left: 30%;
		transform: translate(-120px,0);
	}
}

@media only screen and (max-width: 960px) { 
	.wrapper {
		width: 50%;
		margin-left: 25%;
		transform: translate(0);
	}
}

@media only screen and (max-width: 600px) { 
	.wrapper {
		width: 70%;
		margin-left: 15%;
		transform: translate(0);
	}
}

</style>
